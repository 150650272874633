<script>
import Table from "@/components/tables/table.vue";
import LoadingTypes from "@/core/order/LoadingTypes";
import AddCodeModal from "@/views/pages/applications/components/AddCodeModal.vue";
import CounterpartyApi from "@/api/counterparty/CounterpartyApi";

export default {
  name: "Applications",
  components: {
    AddCodeModal,
    Table
  },
  data() {
    return {
      table: {
        url: '/statistic/statistic_incomplete_applications_by_manager/',
        name: 'Incomplete Applications',
        searchable: true,
        headers: [
          {
            label: 'NUMBER',
            field: 'number',
            align: 'center',
          },
          {
            label: 'Forwarder',
            field: 'forwarder',
            align: 'center',
            searchType: 'select',
            searchOptions: [],
            excel_data: (app) => app.forwarder ? app.forwarder.name ? app.forwarder.name : '' : ''
          },
          {
            label: 'Loading type',
            field: 'loading_type',
            align: 'center',
            searchType: 'select',
            searchOptions: LoadingTypes,
            excel_data: (app) => app.loading_type
                ? app.loading_type
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : ''
          },
          {
            label: 'Departure',
            field: 'departure',
            align: 'center',
            excel_data: (app) => `${app.departure.name} (${app.departure.code})`
          },
          {
            label: 'Destination',
            field: 'destination',
            align: 'center',
            excel_data: (app) => `${app.destination.name} (${app.destination.code})`
          },
          {
            label: 'File',
            field: 'file',
            align: 'center',
            searchable: false,
            excel_data: (app) => app.file
                ? {
                  text: 'FILE',
                  hyperlink: process.env.VUE_APP_ORDER_URL + app.file // Actual link URL
                }
                : ''
          },
          {
            label: 'Date',
            field: 'date',
            align: 'center',
          },
          {
            label: 'Code Price',
            field: 'code_price',
            align: 'center',
            searchable: false,
          },
          {
            label: 'Quantity',
            field: 'quantity',
            align: 'center',
          },
          {
            label: 'Comment',
            field: 'comment',
            align: 'center',
          },
          {
            label: 'Manager',
            field: 'user',
            align: 'center',
            excel_data: (app) => app.user ? app.user.username ? app.user.username : '' : ''
          },
          {
            label: 'Actions',
            field: 'actions',
          },
        ],
      },
      ORDER_CHILD_TYPE: {
        container_order: {
          path: 'orders_container_detail',
          name: 'Container Order'
        },
        wagon_order: {
          path: 'orders_wagon_detail',
          name: 'Wagon Order'
        },
        wagon_empty_order: {
          path: 'orders_empty_wagon_detail',
          name: 'Empty Wagon Order'
        },
      },
      currentApplication: {
        data: {}
      },
      forwarders: [],
      forwarders_has_been_loaded: false
    }
  },
  methods: {
    async downloadFile(url_without_base) {
      let url = process.env.VUE_APP_ORDER_URL + url_without_base
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    showAddCodeModal(application) {
      this.currentApplication.data = application
      document.getElementById('addCodeTriggerBtn').click()
    },
    async getForwarders() {
      let api = new CounterpartyApi()
      let response = await api.getCounterparties('?is_used_for_code=true')
      this.forwarders = response.results.map(forwarder => {
        return {
          value: forwarder.id,
          label: forwarder.name
        }
      })
      this.forwarders_has_been_loaded = true
      this.updateHeaders()
    },
    updateHeaders() {
      this.table.headers = this.table.headers.map(header => {
        if (header.field === 'forwarder') {
          let forwarders = this.forwarders
          forwarders.unshift({value: '', label: ''})
          return {
            ...header,
            searchOptions: forwarders
          };
        }
        return header;
      });
    }
  },
  async mounted() {
    await this.getForwarders()
  }
}
</script>

<template>
  <AddCodeModal :application="currentApplication.data" @update="this.$refs.table.getData()"/>

  <Table v-bind="table" ref="table" class="mb-4">
    <template v-slot:number="slotProps">
      <h5 class="fw-medium link-primary"
          style="cursor: pointer"
      >
        <span class="badge badge-soft-primary">
          {{ slotProps.row.number }}
        </span>
      </h5>
    </template>

    <template v-slot:forwarder="slotProps">
      {{ slotProps.row.forwarder.name }}
    </template>

    <template v-slot:loading_type="{row: application}">
       <span v-if="application.loading_type">
        {{ application.loading_type.replace("_", " ") }}
       </span>
      <span v-else>--</span>
    </template>

    <template v-slot:departure="slotProps">
      {{ slotProps.row.departure.name }}
    </template>

    <template v-slot:destination="slotProps">
      {{ slotProps.row.destination.name }}
    </template>

    <template v-slot:file="slotProps">
      <span v-if="slotProps.row.file" @click="downloadFile(slotProps.row.file)" class="text-danger">
        <font-awesome-icon
            icon="fa-solid fa-file-zipper"
            class="c_icon_hoverable text-secondary"
        />
      </span>
      <span v-else class="text-danger">-</span>
    </template>

    <template v-slot:quantity="slotProps">
      <h6 class="my-0">
        <span class="badge" :class="{
          'badge-outline-danger': slotProps.row.code_count > slotProps.row.quantity,
          'badge-outline-success': slotProps.row.code_count === slotProps.row.quantity,
          'badge-outline-warning': slotProps.row.code_count < slotProps.row.quantity,
        }">
          {{ slotProps.row.code_count }}/{{ slotProps.row.quantity }}
        </span>
      </h6>
    </template>

    <template v-slot:user="{row: application}">
      <template v-if="application.user">
        <router-link :to="{name: 'user_profile', params: {slug: application.user.slug}}">
          <div class="d-flex align-items-center">
            <div class="avatar-xxs flex-shrink-0 me-1">
        <span class="avatar-title bg-light text-primary rounded-circle">
          {{ application.user.username[0].toUpperCase() }}
        </span>
            </div>
            <span>{{ application.user.username }}</span>
          </div>
        </router-link>
      </template>
      <span v-else>--</span>
    </template>

    <template v-slot:actions="{row: application}">

      <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
        <b-button v-if="application.code_count < application.quantity"
                  @click="showAddCodeModal(application)" variant="light">
          <i class="ri-add-fill align-bottom fs-6"></i></b-button>
        <b-button variant="light" class="p-0">
          <div class="px-1">
            <router-link class="text-dark"
                         :to="{ name: 'application_update', params: { id: application.id } }">
              <i class="ri-pencil-fill align-bottom px-1"></i>
            </router-link>
          </div>
        </b-button>
      </b-button-group>

    </template>

  </Table>
</template>

<style scoped>

</style>