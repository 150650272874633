<script>
import Swal from "sweetalert2";
import axios from "axios";
import {CountTo} from "vue3-count-to";
import moment from "moment/moment";
import KPIScoresModal from "@/views/pages/dashboard/modals/KPIScores.vue";

export default {
  name: "ManagersStatistics",
  data() {
    return {
      statistics: [],
      has_loaded_data: false,
      is_loading: false
    }
  },
  components: {
    CountTo,
    KPIScoresModal
  },
  methods: {
    async getManagersStatistics(force_update = false) {
      if (!force_update && this.has_loaded_data) return;
      try {
        this.is_loading = true
        let response = await axios.get('/statistic/manager_statistic/')
        this.statistics = response.data
        this.has_loaded_data = true
        this.is_loading = false
      } catch {
        this.is_loading = false
        let Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        await Toast.fire({
          icon: 'error',
          title: 'Failed to fetch managers statistics'
        })
      }
    },
    managerTotalInvoices(invoice) {
      if (invoice) {
        return {
          total: (invoice.issued || 0) + (invoice.not_issued || 0),
          issued: invoice.issued || 0,
          not_issued: invoice.not_issued || 0
        }
      }
    },
    managerTotalInquiries(inquiry) {
      if (inquiry) {
        return {
          total: (inquiry.pending || 0) + (inquiry.success || 0) + (inquiry.fail || 0) + (inquiry.quoted || 0),
          pending: inquiry.pending || 0,
          success: inquiry.success || 0,
          fail: inquiry.fail || 0,
          quoted: inquiry.quoted || 0
        }
      }
    },
    formatDate(data) {
      if (!data) return '-'
      return moment(data).format('lll')
    },
    formatDateDifference(data) {
      if (!data) return '-'
      return moment(data).fromNow()
    }
  }
}
</script>

<template>

  <KPIScoresModal ref="KPIScoresModal"/>

  <b-row gutter-y="3" class="mb-4">
    <template v-for="item in statistics" :key="`statistics_by_manager_${item.manager}`">
      <b-col xl="12">
        <b-card class="ribbon-box border shadow-none mb-lg-0">
          <div class="ribbon ribbon-success round-shape">
            {{ item.manager ? item.manager.username || '----' : 'Unknown' }}
          </div>
          <div class="ribbon-content mt-4 text-muted">
            <div class="row pb-2">

              <div class="col-12 col-xl-3 border-end">
                <div class="mt-3 mt-md-0 pt-4 px-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">
                      KPI Scores
                    </h5>
                    <div class="d-flex flex-wrap gap-2">
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <b-button @click="this.$refs.KPIScoresModal.openModal(item)" variant="soft-success">
                        <span class="fs-5 fw-medium">
                          <count-to :startVal="0" :endVal="item.total_score || 0" :duration="3000"></count-to>
                        </span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-xl-3 border-end">
                <div class="mt-3 mt-md-0 pt-4 px-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">
                      Total Orders
                    </h5>
                    <div class="d-flex flex-wrap gap-2">
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img style="height: 40px; width: 40px" src="@/assets/images/order-diamond-icon.webp"
                           alt="Order Icon">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0">
                        <count-to :startVal="0" :endVal="item.order_count || 0" :duration="3000"></count-to>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-xl-3 border-end">
                <div class="mt-3 mt-md-0 pt-4 px-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">
                      Total Invoices
                    </h5>
                    <div class="d-flex flex-wrap gap-2">
                      <h5 class="mb-0">
                        <b-badge class="bg-light text-danger float-end
                          align-middle" v-b-tooltip.hover title="Not Issued Invoices">
                          {{ managerTotalInvoices(item.invoice).issued || 0 }}
                        </b-badge>
                      </h5>
                      <h5 class="mb-0">
                        <b-badge class="bg-light text-success float-end
                          align-middle" v-b-tooltip.hover title="Issued Invoices">
                          {{ managerTotalInvoices(item.invoice).not_issued || 0 }}
                        </b-badge>
                      </h5>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img style="height: 40px; width: 40px" src="@/assets/images/invoice.png" alt="Order Icon">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0">
                        <count-to :startVal="0" :endVal="managerTotalInvoices(item.invoice).total || 0"
                                  :duration="3000"></count-to>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-xl-3 border-end">
                <div class="mt-3 mt-md-0 pt-4 px-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">
                      Total Inquiries
                    </h5>
                    <div class="d-flex flex-wrap gap-2">
                      <h5 class="mb-0">
                        <b-badge class="bg-light text-warning float-end
                          align-middle" v-b-tooltip.hover title="Pending Inquiries">
                          {{ managerTotalInquiries(item.inquiry).pending || 0 }}
                        </b-badge>
                      </h5>
                      <h5 class="mb-0">
                        <b-badge class="bg-light text-secondary float-end
                          align-middle" v-b-tooltip.hover title="Quoted Inquiries">
                          {{ managerTotalInquiries(item.inquiry).quoted || 0 }}
                        </b-badge>
                      </h5>
                      <h5 class="mb-0">
                        <b-badge class="bg-light text-success float-end
                          align-middle" v-b-tooltip.hover title="Successful Inquiries">
                          {{ managerTotalInquiries(item.inquiry).success || 0 }}
                        </b-badge>
                      </h5>
                      <h5 class="mb-0">
                        <b-badge class="bg-light text-danger float-end
                          align-middle" v-b-tooltip.hover title="Failed Inquiries">
                          {{ managerTotalInquiries(item.inquiry).fail || 0 }}
                        </b-badge>
                      </h5>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img style="height: 40px; width: 40px" src="@/assets/images/inquiry.webp" alt="Order Icon">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0">
                        <count-to :startVal="0" :endVal="managerTotalInquiries(item.inquiry).total || 0"
                                  :duration="3000"></count-to>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <!--              <div class="col-12 col-xl-3 border-end">-->
              <!--                <div class="mt-3 mt-lg-0 pt-4 px-3">-->
              <!--                  <h5 class="text-muted text-uppercase fs-13">-->
              <!--                    Last Login-->
              <!--                    <b-badge class="bg-light text-info float-end-->
              <!--                          align-middle" v-b-tooltip.hover title="Last login time">-->
              <!--                      <i class="mdi mdi-clock-time-three-outline me-1"></i>-->
              <!--                      {{ formatDateDifference(item.manager.last_login) }}-->
              <!--                    </b-badge>-->
              <!--                  </h5>-->
              <!--                  <div class="d-flex align-items-center">-->
              <!--                    <div class="flex-shrink-0">-->
              <!--                      <i class="mdi mdi-clock-time-three-outline display-6 text-muted"></i>-->
              <!--                    </div>-->
              <!--                    <div class="flex-grow-1 ms-3">-->
              <!--                      <h2 class="mb-0">-->
              <!--                        {{ formatDate(item.manager.last_login) }}-->
              <!--                      </h2>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

            </div>
          </div>
        </b-card>
      </b-col>
    </template>
  </b-row>
</template>

<style scoped>

</style>