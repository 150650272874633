<script>
import axios from "axios";
import Skeleton from "@/components/custom/skeleton.vue";
import {boolean} from "yup";

export default {
  components: {Skeleton},
  emits: ['openCustomerStatisticsModal', 'loaded'],
  props: {
    statistics_year: {
      type: Number,
      required: true,
      default: () => new Date().getFullYear()
    },
    loading_statistics_by_selected_year: {
      type: boolean,
      default: () => false
    }
  },
  data() {
    return {
      request: {
        status: '',
        message: '',
        details: ''
      },
      items: [],
    }
  },
  methods: {
    async getStatistics() {
      this.request.status = 'loading'
      try {
        let response = await axios.get('/statistic/top_customers/', {
          params: {
            year: this.statistics_year
          }
        })
        this.items = response.data.top_customers || []
        this.$emit('loaded', true)
      } catch {
        this.$emit('loaded', true)
        this.request.status = 'error'
        this.request.message = 'Error while loading statistics'
        this.request.details = 'Please try again later'
        return
      }
      this.request.status = 'success'
    },
    openCustomerStatisticsModal(company) {
      this.$emit('openCustomerStatisticsModal', {
        value: company.id,
        label: company.name,
        slug: company.slug
      })
    },
    normalizeText(text) {
      try {
        if (!text) return ''
        return text.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
      } catch {
        return ''
      }
    },
  },
}
</script>

<template>
  <div v-if="request.status === 'success'">
    <b-card
        v-for="item in items" :key="`item_${item}`"
        no-body>


      <b-card-header class="d-flex align-items-center">
        <h6 class="card-title mb-0 flex-grow-1">
          <span v-if="!loading_statistics_by_selected_year" class="badge badge-gradient-info me-2">{{ statistics_year }}</span>
          <i v-else class="mdi mdi-spin mdi-loading me-2 text-success"></i>
          {{ normalizeText(item.position) }}
        </h6>
        <ul class="nav nav-pills card-header-pills" role="tablist">
          <!--          <li class="nav-item"><a class="nav-link" :href="`#managers_${item.position}`" data-bs-toggle="tab" role="tab">-->
          <!--            Managers </a></li>-->
          <li class="nav-item">
            <a class="nav-link active" :href="`#customers_${item.position}`" data-bs-toggle="tab"
               role="tab"> Customers </a></li>
        </ul>
      </b-card-header>

      <b-card-body>
        <div class="tab-content text-muted">
          <div class="tab-pane active" :id="`customers_${item.position}`" role="tabpanel">
            <div class="table-responsive table-card">
              <div data-simplebar style="max-height: 300px;">
                <ul class="list-group list-group-flush" v-if="item.companies && item.companies.length > 0">
                  <li v-for="company in item.companies" :key="`company_${company}`"
                      class="list-group-item list-group-item-action"
                      @click="openCustomerStatisticsModal(company)"
                  >
                    <b-row class="align-items-center justify-content-between">
                      <b-col lg="2">
                        <div
                            class="avatar-xs object-cover text-info rounded bg-soft-info d-flex justify-content-center align-items-center"
                        >
                          <strong>{{ company.name ? company.name[0] : '-' }}</strong>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div>
                          <h6 v-b-tooltip.hover :title="company.name"
                              class="fs-14 mb-0 text-truncate">{{ company.name }}</h6>
                          <small class="text-muted">Company's name</small>
                        </div>
                      </b-col>
                      <b-col lg="4">
                        <h6 v-b-tooltip.hover title="Total agreed rate" class="text-success mb-0">$
                          {{ (company.agreed_rate || 0).toLocaleString() }}</h6>
                        <small class="text-muted">Agreed rate</small>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
                <ul v-else>
                  <li class="pt-3">No data to show</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane" :id="`managers_${item.position}`" role="tabpanel">
            By Managers
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
  <div v-else-if="request.status === 'loading'">
    <b-card
        v-for="item in 3" :key="`item_loading_${item}`"
        no-body>

      <b-card-header class="d-flex align-items-center">
        <skeleton custom_style="min-height: 15px; min-width: 250px"/>
      </b-card-header>

      <b-card-body>
        <div class="tab-content text-muted">
          <div class="tab-pane active" :id="`customers_${item.position}`" role="tabpanel">
            <div class="table-responsive table-card">
              <div data-simplebar style="max-height: 300px;">
                <ul class="list-group list-group-flush">
                  <li v-for="company in 5" :key="`company_${company}`"
                      class="list-group-item list-group-item-action"
                  >
                    <b-row class="align-items-center justify-content-between">
                      <b-col lg="1">
                        <div
                            class="avatar-xs object-cover text-info rounded bg-soft-info d-flex justify-content-center align-items-center"
                        >
                          <i class="mdi mdi-loading mdi-spin"></i>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div>
                          <h6 class="fs-14 mb-0 text-truncate" style="max-width: 150px">
                            <skeleton/>
                          </h6>
                          <small class="text-muted">Company's name</small>
                        </div>
                      </b-col>
                      <b-col lg="4">
                        <h6 v-b-tooltip.hover title="Total agreed rate" class="text-success mb-0">$
                          0.00 </h6>
                        <small class="text-muted">Agreed rate</small>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane" :id="`managers_${item.position}`" role="tabpanel">
            By Managers
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
  <b-alert v-else-if="request.status === 'error'"
           dismissible show variant="danger" class="alert-additional fade show" role="alert">
    <div class="alert-body">
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      <div class="d-flex">
        <div class="flex-shrink-0 me-3">
          <i class="ri-error-warning-line fs-16 align-middle"></i>
        </div>
        <div class="flex-grow-1">
          <h5 class="alert-heading">Couldn't load order type statistics!</h5>
          <p class="mb-0">Please, try to refresh the page</p>
        </div>
      </div>
    </div>
  </b-alert>
</template>