<script>
import SelectItems from "@/components/custom/SelectItems.vue";

export default {
  name: "CustomerDetailsSearch",
  components: {
    SelectItems
  },
  emits: ['onCustomerSelect', 'onYearSelect'],
  data() {
    return {
      multiselect: {
        api_url: '/customer/companies/?name=',
        placeholder: "Select a customer to see the details",
        extraDataToBeReturned: ['slug']
      },
      statistics_year: new Date().getFullYear(),
      years_options: []
    };
  },
  methods: {
    onCustomerSelect(customer) {
      this.$emit('onCustomerSelect', customer)
    },
    onStatisticsYearChanged() {
      this.$emit('onYearSelect', this.statistics_year)
    }
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    for (let year = 2023; year <= currentYear; year++) {
      this.years_options.push(year);
    }
  },
  watch: {
    statistics_year:  {
      handler() {
        this.onStatisticsYearChanged()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<template>
  <div class="row align-items-center g-3">
    <div class="col-12 col-lg-6">
      <label class="form-label">Customer Details</label>
      <SelectItems
          @onSelect="onCustomerSelect"
          v-bind="multiselect"/>
    </div>
    <div class="col-12 col-lg-6">
      <label class="form-label">Year for Statistics</label>
      <select v-model="statistics_year" class="form-select">
        <option v-for="year in years_options" :key="`statistics_${year}`">{{ year }}</option>
      </select>
    </div>
  </div>
</template>

<style scoped>

</style>