<script>
import Table from "@/components/tables/table.vue";
import moment from "moment";

export default {
  emits: ['on-company-ids-loaded'],
  components: {
    Table
  },
  data() {
    return {
      table: {
        name: 'Orders',
        url: '/statistic/order_incomplete_statistic/',
        headers: [
          {
            label: 'Order Number',
            field: 'order_number',
            align: 'center'
          },
          {
            label: 'Company',
            field: 'company',
            align: 'center',
            maxWidth: '200px'
          },
          {
            label: 'Date',
            field: 'order_date',
            align: 'center'
          },
          {
            label: 'Lot Number',
            field: 'lot_number',
            align: 'center'
          },
          {
            label: 'Containers',
            field: 'is_completed',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Completed', value: true},
              {label: 'Not Completed', value: false}
            ]
          },
          {
            label: 'Invoice Issued',
            field: 'has_invoice',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Yes', value: true},
              {label: 'No', value: false}
            ]
          },
        ],
        perPage: 5,
        searchable: true
      },
      order_company_ids: []
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('ll');
    },
    onDataLoaded(data) {
      try {
        this.order_company_ids = []
        data.forEach(o => {
          if (!this.order_company_ids.includes(o.company.id)) {
            this.order_company_ids.push(o.company.id);
          }
        });
        this.$emit('on-company-ids-loaded', this.order_company_ids);
      } catch {
        this.$emit('on-company-ids-loaded', []);
        this.order_company_ids = []
      }
    }
  }
}
</script>

<template>
  <Table v-bind="table"
         class="mb-4"
         @on-loaded="onDataLoaded($event)"
  >

    <template v-slot:order_number="{row: data}">
      <h5 class="fw-medium link-primary"
          style="cursor: pointer"
      >
        <span class="badge badge-soft-primary">
          {{ data.order_number }}
        </span>
      </h5>
    </template>

    <template v-slot:company="{row: data}">
      <div v-b-tooltip.hover :title="data.company.name"
           class="text-truncate">{{ data.company.name }}
      </div>
    </template>

    <template v-slot:date="{row: data}">
      <div class="text-truncate">{{ formatDate(data.order_date) }}</div>
    </template>

    <template v-slot:is_completed="{row: data}">
      <i v-if="data.is_completed" class="mdi mdi-checkbox-multiple-marked-circle fs-5 text-success"></i>
      <i v-else class="mdi mdi-close-circle-multiple fs-5 text-danger"></i>
    </template>

    <template v-slot:has_invoice="{row: data}">
      <i v-if="data.has_invoice" class="mdi mdi-checkbox-multiple-marked-circle fs-5 text-success"></i>
      <i v-else class="mdi mdi-close-circle-multiple fs-5 text-danger"></i>
    </template>
  </Table>
</template>