<script>
import Swal from "sweetalert2";
import axios from "axios";
import {boolean} from "yup";

export default {
  name: "TrainAverageTime",
  props: {
    statistics_year: {
      type: Number,
      required: true,
      default: () => new Date().getFullYear()
    },
    loading_statistics_by_selected_year: {
      type: boolean,
      default: () => false
    }
  },
  emits: ['loaded'],
  data() {
    return {
      data: {
        widgets: [],
        trains: [],
        current_page: 1,
        total_pages: 0
      }
    }
  },
  computed: {
    paginated_trains_list() {
      return this.data.trains.slice((this.data.current_page - 1) * 25, this.data.current_page * 25)
    }
  },
  methods: {
    async getStatistics() {
      try {
        let response = await axios.get("/train/list/shipment-stats/", {
          params: {
            year: this.statistics_year
          }
        });
        this.data.widgets = response.data.metrics || []
        this.data.trains = response.data.statistics || []
        this.data.total_pages = Math.ceil(this.data.trains.length / 25)
        this.$emit('loaded', true)
      } catch {
        this.$emit('loaded', false)
        let Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

        await Toast.fire({
          icon: "error",
          title: "Couldn't load shipment statistics",
        });
      }
    },
    formatDuration(duration) {
      if (duration) {
        return duration.split(' ')[0] + ' days'
      }
      return '-'
    }
  },
}
</script>

<template>

  <div class="card"><!----><!---->
    <div class="card-body position-relative"><!----><!---->
      <h5 class="mb-3">
        <span v-if="!loading_statistics_by_selected_year" class="badge badge-gradient-info me-2">{{ statistics_year }}</span>
        <i v-else class="mdi mdi-spin mdi-loading me-2 text-success"></i>
        Transit Statistics
      </h5>
      <p class="text-muted">
        This shows the min, max and average time taken for a shipment to be completed
      </p>
      <div class="vstack gap-2 mb-3">
        <div
            v-for="widget in data.widgets" :key="`widget_${widget.type}`"
            class="form-check card-radio">
          <label class="form-check-label pe-2" style="cursor: default"
                 for="listGroupRadioGrid1">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <div class="avatar-xs">
                  <div class="avatar-title bg-success-subtle text-uppercase fs-18 rounded">
                    {{ widget.type[0] }}
                  </div>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="d-flex">
                  <VTooltip>
                    <h6 class="d-inline-block mb-1">{{ widget.type }}</h6>
                    <template #popper>Shipment type</template>
                  </VTooltip>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex gap-4">
                    <div class="d-flex">
                      <VTooltip>
                        <span class="text-muted me-1">Min: </span>
                        <b class="pay-amount">{{ formatDuration(widget.min.duration) }}</b>
                        <template #popper>
                          {{ widget.min.name }}
                        </template>
                      </VTooltip>
                    </div>
                    <div class="d-flex">
                      <VTooltip>
                        <span class="text-muted me-1">Max: </span>
                        <b class="pay-amount">{{ formatDuration(widget.max.duration) }}</b>
                        <template #popper>
                          {{ widget.max.name }}
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                  <div>
                    <div class="d-flex">
                      <VTooltip>
                        <span class="text-muted me-1">Avg: </span>
                        <b class="pay-amount">
                          {{ formatDuration(widget.average) }}
                        </b>
                        <template #popper>
                          Average time taken for a shipment <br>to be completed in this category
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div>
        <div class="table-responsive">
          <table class="table table-striped mb-0">
            <tbody>
            <tr v-for="(train, index) in paginated_trains_list" :key="`train_${train.name}`">
              <th scope="row">
                {{ (data.current_page - 1) * 25 + index + 1 }}
              </th>
              <td>{{ train.name }}</td>
              <td>{{ formatDuration(train.duration) }}</td>
              <td class="text-center">
                <span class="badge"
                      :class="{
                  'bg-warning': train.train_type === 'import',
                  'bg-success': train.train_type === 'export',
                  'bg-primary': train.train_type === 'transit',
                      }"
                >
                {{ train.train_type }}
              </span></td>
            </tr>
            </tbody>
          </table>
        </div>

        <nav class="mb-0 pb-0 mt-3" v-if="paginated_trains_list.length > 0">
          <ul class="pagination mb-0 pb-0">
            <li class="page-item"
                :class="{
                  'disabled': data.current_page === 1,
                  'cursor-pointer': data.current_page > 1
                }"
                @click="data.current_page === 1 ? null : data.current_page = data.current_page - 1"
            >
              <a class="page-link" tabindex="-1">Previous</a>
            </li>
            <li
                v-for="page in data.total_pages" :key="`page_${page}`"
                class="page-item cursor-pointer" :class="{'active': data.current_page === page}"
            >
              <a @click="data.current_page = page"
                 class="page-link">{{ page }}</a>
            </li>
            <li class="page-item"
                :class="{
                  'disabled': data.current_page === data.total_pages,
                  'cursor-pointer': data.current_page < data.total_pages
                }"
                @click="data.current_page === data.total_pages ? null : data.current_page = data.current_page + 1"
            >
              <a class="page-link">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>