<script>
import axios from "axios";
import {boolean} from "yup";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  name: "OrdersMonthly",
  props: {
    statistics_year: {
      type: Number,
      required: true,
      default: () => new Date().getFullYear()
    },
    loading_statistics_by_selected_year: {
      type: boolean,
      default: () => false
    }
  },
  emits: ['loaded'],
  data() {
    return {
      month_dict: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
      chart: {
        series: [{
          name: 'Revenue',
          type: 'column',
          data: []
        }, {
          name: 'Expense',
          type: 'column',
          data: []
        }, {
          name: 'Profit',
          type: 'line',
          data: [],
        },
          {
            name: 'Total Trains',
            type: 'area',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false,
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.05
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '75%'
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [2, 3],
            style: {
              fontSize: '14px',
            },
            formatter: function (val, opts) {
              const seriesIndex = opts.seriesIndex;
              const seriesName = opts.w.globals.seriesNames[seriesIndex];

              if (seriesName === "Total Trains") {
                return val.toLocaleString();
              }
              return "$ " + val.toLocaleString()
            }
          },
          colors: getChartColorsArray('["--vz-primary", "--vz-info", "--vz-success",  "--vz-success"]'),
          stroke: {
            width: [2, 0, 0, 5],
            curve: 'smooth'
          },
          title: {
            text: 'Monthly Orders Statistics',
            align: 'center',
          },
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: [0.85, 0.85, 1, 0.25],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100]
            }
          },
          yaxis: [
            {
              labels: {
                style: {
                  colors: '#008FFB',
                }
              },
              title: {
                show: false,
              },
              tooltip: {
                enabled: true
              },
              axisTicks: {
                show: false,
              },
            },
            {
              seriesName: 'Profit',
              opposite: true,
              labels: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            {
              seriesName: 'Revenue',
              axisTicks: {
                show: true,
              },
              labels: {
                show: false,
              },
            },
            {
              seriesName: 'Total Trains',
              axisTicks: {
                show: true,
              },
              labels: {
                show: false,
              },
              color: '#FF1654',
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y, opts) {
                const seriesIndex = opts.seriesIndex;
                const seriesName = opts.w.globals.seriesNames[seriesIndex];

                // Check if the series is named "Count"
                if (seriesName === "Total Trains") {
                  return y.toLocaleString();
                }

                // Add dollar sign for other series
                return "$ " + y.toLocaleString();
              }
            }
          },
          legend: {
            horizontalAlign: 'left',
          }
        },
      },
      request: {
        status: '',
        message: null,
        details: null,
      },
    }
  },
  methods: {
    async getStatistics(position) {
      this.request.status = 'loading'
      this.request.message = null
      this.request.details = null
      try {
        let response = await axios.get('/statistic/order_by_month/', {
          params: {
            position: position || undefined,
            year: this.statistics_year
          }
        })
        this.chart.chartOptions.xaxis.categories = response.data.statistic.map(item => this.month_dict[item.month])
        this.chart.series[0].data = response.data.statistic.map(item => parseFloat(item.total_revenue || 0).toFixed(2))
        this.chart.series[1].data = response.data.statistic.map(item => parseFloat(item.total_expanse || 0).toFixed(2))
        this.chart.series[2].data = response.data.statistic.map(item => (parseFloat(item.total_revenue || 0) - parseFloat(item.total_expanse || 0)).toFixed(2))
        this.$emit('loaded', true)
      } catch {
        this.$emit('loaded', true)
        this.request.status = 'error'
        this.request.message = 'Error while loading statistics'
        this.request.details = 'Please try again later'
        return
      }
      this.request.status = 'success'
      await this.getTrainStatistics(position)
    },
    async getTrainStatistics(position) {
      try {
        let response = await axios.get('/statistic/train_statistic_monthly/', {
          params: {
            position: position || undefined,
            year: this.statistics_year
          }
        })
        this.chart.series[3].data = response.data.statistic.map(item => {
          let total_trains = 0
          item.details.forEach(type => {
            total_trains += type.trains.length
          })
          return total_trains
        })
      } catch {
        console.log("Failed to load train statistics")
      }
    },
  },
}
</script>

<template>
  <div id="orders_monthly">
    <b-card no-body>
      <b-card-header class="bg-transparent">
        <b-card-title class="mb-0">
          <span v-if="!loading_statistics_by_selected_year" class="badge badge-gradient-info me-2">{{
              statistics_year
            }}</span>
          <i v-else class="mdi mdi-spin mdi-loading me-2 text-success"></i>
          Orders Monthly
        </b-card-title>
      </b-card-header>
      <b-card-body class="pb-1">
        <apexchart v-if="request.status === 'success'" type="line" height="550" :options="chart.chartOptions"
                   :series="chart.series"></apexchart>
        <div v-else-if="request.status === 'error'" style="height: 500px">
          <!-- Danger Alert -->
          <b-alert dismissible show variant="danger" class="alert-additional fade show" role="alert">
            <div class="alert-body">
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <i class="ri-error-warning-line fs-16 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  <h5 class="alert-heading">{{ request.message || 'Couldn\'t load this chart' }}</h5>
                  <p class="mb-0">
                    {{ request.details || 'Try to reload the page up or try again later' }}
                  </p>
                </div>
              </div>
            </div>
          </b-alert>
        </div>
        <div v-else-if="request.status === 'loading'" style="min-height: 400px">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>

</style>