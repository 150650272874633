<script>
import skeleton from "@/components/custom/skeleton.vue";
import axios from "axios";

export default {
  emits: ['updateMonthlyOrderStatisticsChart', 'loaded'],
  props: {
    statistics_year: {
      type: Number,
      required: true,
      default: () => new Date().getFullYear()
    },
  },
  data() {
    return {
      order_types: [],
      request: {
        status: 'loading',
        message: '',
        details: ''
      },
      currently_clicked: {},
    };
  },
  methods: {
    async getStatistics() {
      this.request.status = 'loading'
      try {
        let response = await axios.get('/statistic/order_by_type/', {
          params: {
            year: this.statistics_year
          }
        })
        this.order_types = response.data.statistics || []
        this.request.status = 'success'
        this.$emit('loaded', true)
      } catch {
        this.$emit('loaded', true)
        this.request.status = 'error'
        this.request.message = 'Error while loading statistics'
        this.request.details = 'Please try again later'
        return
      }
      this.request.status = 'success'
    },
    normalizeText(text) {
      try {
        if (!text) return ''
        return text.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
      } catch {
        return ''
      }
    },
    updateMonthlyOrderStatisticsChart(item) {
      this.$emit('updateMonthlyOrderStatisticsChart', this.currently_clicked.position === item.position ? null : item)
      this.currently_clicked = item.position === this.currently_clicked.position ? {} : item
    },
  },
  components: {
    skeleton
  },
  mounted() {
    this.getStatistics()
  }
};
</script>

<template>
  <div v-if="request.status === 'success'">
    <b-row>
      <b-col xl="4"
             v-for="(item, index) of order_types" :key="`index_${item.label}_${index}`">
        <b-card no-body
                class="card-animate cursor-pointer"
                :class="{
          'currently_clicked_position border border-success': currently_clicked.position === item.position,
                }"
                @click="updateMonthlyOrderStatisticsChart(item)"
        >
          <b-card-body>
            <div class="d-flex align-items-center">
              <div class="avatar-md flex-shrink-0">
            <span class="avatar-title rounded-2 bg-soft-info text-info fs-2">
              {{ normalizeText(item.position)[0] }}
            </span>
              </div>
              <div class="flex-grow-1 overflow-hidden ms-3">
                <div class="d-flex align-items-center mb-3">
                  <h4 class="fs-4 flex-grow-1 mb-0">
                    <span class="counter-value">
                      {{ normalizeText(item.position) }}
                    </span>
                  </h4>
                  <span :class="`badge badge-soft-success fs-12`">
                  $ {{ (parseFloat(item.total_revenue) - parseFloat(item.total_expanse)).toLocaleString() }}
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="text-muted text-truncate mb-0">
                    <small class="d-block">Revenue</small>
                    $ {{ (item.total_revenue).toLocaleString() }}
                  </p>
                  <p class="text-muted text-truncate text-end mb-0">
                    <small class="d-block">Expense</small>
                    $ {{ (item.total_expanse).toLocaleString() }}
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column gap-2 mt-3">
              <div data-simplebar style="max-height: 215px;">
                <b-list-group>
                  <b-list-group-item
                      v-for="i in (item.details || [])" :key="`type_${i}`" class="list-group-item"
                  >
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="d-flex">
                          <div class="flex-shrink-0 avatar-xs">
                            <div class="avatar-title bg-light text-danger rounded">
                              <i :class="{
                                'text-warning': i.type.toLowerCase() === 'import',
                                'text-success': i.type.toLowerCase() === 'export',
                                'text-primary': i.type.toLowerCase() === 'transit',
                              }"
                                 class="mdi mdi-circle"></i>
                            </div>
                          </div>
                          <div class="flex-shrink-0 ms-2">
                            <h6 class="fs-14 mb-0">{{ i.type }}</h6>
                            <small v-b-tooltip.hover title="Total Revenue" class="text-muted">$ {{
                                (parseFloat(i.total_revenue)).toLocaleString()
                              }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="flex-shrink-0 text-end">
                        <h6 v-b-tooltip.hover title="Total Profit" class="fs-12 text-success mb-0">
                          + ${{
                            (parseFloat(i.total_revenue) - parseFloat(i.total_expanse)).toLocaleString()
                          }}
                        </h6>
                        <small v-b-tooltip.hover title="Total Expanse" class="text-muted">- ${{
                            (parseFloat(i.total_expanse)).toLocaleString()
                          }}</small>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <div v-else-if="request.status === 'loading'">
    <b-row>
      <b-col xl="4"
             v-for="item of 3"
             :key="`index_loading_${item}`">
        <b-card no-body class="card-animate cursor-pointer">
          <b-card-body>
            <div class="d-flex align-items-center">
              <div class="avatar-md flex-shrink-0">
            <span class="avatar-title rounded-2 bg-soft-info text-info fs-2">
            </span>
              </div>
              <div class="flex-grow-1 overflow-hidden ms-3">
                <div class="d-flex align-items-center mb-3">
                  <h4 class="fs-4 flex-grow-1 mb-0">
                    <span class="counter-value">
                     <skeleton custom_style="min-height: 15px; max-width: 250px"/>
                    </span>
                  </h4>
                  <span :class="`badge badge-soft-success fs-12`">
                  $ 0.00
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="text-muted text-truncate mb-0">
                    <small class="d-block">Revenue</small>
                    $ 0.00
                  </p>
                  <p class="text-muted text-truncate text-end mb-0">
                    <small class="d-block">Expanse</small>
                    $ 0.00
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column gap-2 mt-3">
              <div data-simplebar style="max-height: 215px;">
                <b-list-group>
                  <b-list-group-item
                      v-for="i in 3" :key="`type_${i}`" class="list-group-item"
                  >
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="d-flex">
                          <div class="flex-shrink-0 avatar-xs">
                            <div class="avatar-title bg-light text-danger rounded text-secondary">
                              <i class="mdi mdi-circle text-info"></i>
                            </div>
                          </div>
                          <div class="flex-shrink-0 ms-2">
                            <h6 class="fs-14 mb-0" style="min-width: 100px">
                              <skeleton/>
                            </h6>
                            <small v-b-tooltip.hover title="Total Revenue" class="text-muted">$ 0.00</small>
                          </div>
                        </div>
                      </div>
                      <div class="flex-shrink-0 text-end">
                        <h6 v-b-tooltip.hover title="Total Profit" class="fs-12 text-success mb-0">
                          + $0.00
                        </h6>
                        <small v-b-tooltip.hover title="Total Expanse" class="text-muted">- $0.00</small>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <div v-else-if="request.status === 'error'">
    <b-row>
      <b-col xl="4" v-for="item of 3" :key="`item_${item}`">
        <b-card no-body class="card-animate">
          <b-card-body>
            <div class="d-flex align-items-center py-2">
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title rounded-2 bg-soft-danger text-danger fs-2 p-0">
                    <i class="mdi mdi-exclamation-thick"></i>
                </span>
              </div>
              <div class="flex-grow-1 overflow-hidden ms-3">
                <p class="text-uppercase fw-medium text-danger text-truncate mb-2">
                  Could not load this data
                </p>
                <div class="d-flex align-items-center">
                  <h6 class="flex-grow-1 mb-0">
                    <span class="counter-value text-muted">
                      {{ request.details || 'Reload the page up or try again later' }}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.currently_clicked_position {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  transform: scale(1.03);
}
</style>