<script>
import {VueEcharts} from "vue3-echarts";
import axios from "axios";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  components: {VueEcharts},
  data() {
    const sankeyChart = {
      color: getChartColorsArray('["--vz-info", "--vz-success", "--vz-warning", "--vz-danger", "--vz-primary"]'),
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove'
      },
      series: {
        type: 'sankey',
        layout: 'none',
        emphasis: {
          focus: 'adjacency'
        },
        data: [],
        links: [],

      },
      textStyle: {
        fontFamily: 'Poppins, sans-serif'
      },
    }
    return {
      sankeyChart: sankeyChart,
      chartHeight: 550,
      showModal: false,
      train: {},

      details: [],
      loading: true,
    };
  },
  computed: {
    option() {
      return {
        sankeyChart: this.sankeyChart,
      };
    },
  },
  methods: {
    openModal(train) {
      this.train = train;
      this.showModal = true;
      this.$nextTick(() => {
        this.$refs.chart.echarts.resize();
      });
    },
    async getStatistics() {
      this.loading = true

      try {
        let response = await axios.get(`statistic/train_detail_statistic_finance/${this.train.lot_number}/`)
        this.details = response.data.statistic || []
        this.sankeyChart.series.data = [
          {
            name: 'Total Revenue',
          },
          {
            name: 'Total Profit',
          },
          {
            name: 'Total Expense',
          }
        ]
        this.details.forEach(item => {
          const companyExists = this.sankeyChart.series.data.some(dataItem => dataItem.name === item.company__name);

          if (!companyExists) {
            this.sankeyChart.series.data.push({
              name: item.company__name,
            });
          }

          item.expanses.forEach(expense => {
            const counterpartyExists = this.sankeyChart.series.data.some(dataItem => dataItem.name === expense.counterparty);

            if (!counterpartyExists) {
              this.sankeyChart.series.data.push({
                name: expense.counterparty
              });
            }

            const linkExists = this.sankeyChart.series.links.some(link =>
                link.source === item.company__name && link.target === expense.counterparty
            );

            if (!linkExists) {
              this.sankeyChart.series.links.push({
                source: item.company__name,
                target: expense.counterparty,
                value: expense.total_actual_cost,
                label: {show: true, position: 'top', formatter: '{b}: {c}'}
              });
            }

            const totalProfitLinkExists = this.sankeyChart.series.links.some(link =>
                link.source === 'Total Profit' && link.target === item.company__name
            );

            if (!totalProfitLinkExists) {
              const totalActualCostSum = item.expanses.reduce((sum, expense) => sum + expense.total_actual_cost, 0);
              this.sankeyChart.series.links.push({
                source: 'Total Profit',
                target: item.company__name,
                value: (parseFloat(item.total_revenue) - parseFloat(totalActualCostSum)).toFixed(2) /* Add the appropriate value here */,
              });
            }


            const totalExpenseLinkExists = this.sankeyChart.series.links.some(link =>
                link.source === 'Total Expense' && link.target === item.company__name
            );

            if (!totalExpenseLinkExists) {
              const totalActualCostSum = item.expanses.reduce((sum, expense) => parseFloat(sum || 0) + parseFloat(expense.total_actual_cost), 0);
              this.sankeyChart.series.links.push({
                source: 'Total Expense',
                target: item.company__name,
                value: parseFloat(totalActualCostSum).toFixed(2) /* Add the appropriate value here */,
              });
            }


            const totalRevenueToProfitLinkExists = this.sankeyChart.series.links.some(link =>
                link.source === 'Total Revenue' && link.target === 'Total Profit'
            );

            if (!totalRevenueToProfitLinkExists) {
              let totalRevenueValue = this.details.reduce((sum, item) => sum + parseFloat(item.total_revenue), 0);
              let totalExpenseValue = this.details.reduce((sum, item) => sum + parseFloat(item.total_expenses), 0);
              this.sankeyChart.series.links.push({
                source: 'Total Revenue',
                target: 'Total Profit',
                value: (parseFloat(totalRevenueValue) - parseFloat(totalExpenseValue)).toFixed(2) /* Add the appropriate value here */,
                lineStyle: {
                  color: '#ccebc5', // Use the mapping or a default color
                  opacity: 0.6,
                },
              });
            }

            const totalRevenueToExpenseLinkExists = this.sankeyChart.series.links.some(link =>
                link.source === 'Total Revenue' && link.target === 'Total Expense'
            );

            if (!totalRevenueToExpenseLinkExists) {
              let totalExpenseValue = this.details.reduce((sum, item) => sum + parseFloat(item.total_expenses), 0);
              this.sankeyChart.series.links.push({
                source: 'Total Revenue',
                target: 'Total Expense',
                value: parseFloat(totalExpenseValue).toFixed(2) /* Add the appropriate value here */,
                lineStyle: {
                  color: '#fbb4ae', // Use the mapping or a default color
                  opacity: 0.6,
                },
              });
            }

          });
        });
      } catch {
        console.log("error")
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    updateChartHeight() {
      const numLinks = this.option.sankeyChart.series.links.length;
      this.chartHeight = Math.max(numLinks * 25 > 250 ? numLinks * 25 : 250, numLinks * 30);
    },
  },
  watch: {
    showModal: {
      handler: function (val) {
        if (val) {
          this.sankeyChart.series.data = []
          this.sankeyChart.series.links = []
          this.getStatistics()
        }
      },
      immediate: false
    },
    details: {
      handler: function () {
        this.updateChartHeight();
      },
      immediate: false
    }
  }
};
</script>

<template>
  <b-modal v-model="showModal" :title="train.lot_number" size="xl" centered hide-footer>
    <b-row>
      <b-col :sm="12" v-if="!loading">
        <vue-echarts :option="loading ? [] : option.sankeyChart" :style="{height: `${chartHeight}px`, width: '100%'}"
                     ref="chart"/>
      </b-col>
      <b-col :sm="12" v-else>
        <b-spinner/>
      </b-col>
    </b-row>
  </b-modal>
</template>