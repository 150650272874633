<script>
import Table from "@/components/tables/table.vue";

export default {
  components: {
    Table
  },
  data() {
    return {
      table: {
        name: 'Unpaid Invoices',
        url: '/statistic/statistic_invoices_by_manager/',
        headers: [
          {
            label: 'Date',
            field: 'date',
            align: 'center'
          },
          {
            label: 'Company',
            field: 'invoice_company',
            align: 'center',
            maxWidth: '200px'
          },
          {
            label: 'File',
            field: 'file',
            align: 'center',
            searchable: false
          },
          {
            label: 'Status',
            field: 'paid_status',
            align: 'center',
            searchable: false
          },
          {
            label: 'Total Cost',
            field: 'total_cost',
            align: 'center'
          },
        ],
        perPage: 6,
        searchable: true
      }
    }
  },
  methods: {
    async downloadFile(url_without_base) {
      let url = process.env.VUE_APP_ORDER_URL + url_without_base
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
  }
}
</script>

<template>
  <Table v-bind="table" class="mb-4">

    <template v-slot:file="{row: data}">
      <div @click="downloadFile(data.file)" v-if="data.file"
           class="d-flex gap-2 align-items-center justify-content-center">
        <font-awesome-icon
            icon="fa-solid fa-file-zipper"
            class="c_icon_hoverable text-secondary"
        />
        <span>{{ data.id }}</span>
      </div>
      <span v-else class="text-danger">-</span>
    </template>

    <template v-slot:invoice_company="{row: data}">
      <div v-b-tooltip.hover :title="data.invoice_company.name"
           class="text-truncate">{{ data.invoice_company.name }}
      </div>
    </template>

    <template v-slot:date="{row: data}">
      <div class="text-truncate">{{ (data.date) }}</div>
    </template>

    <template v-slot:paid_status="{row: data}">
      {{ data.paid_status }}
    </template>

  </Table>
</template>