<script>
import {
  CountTo
} from "vue3-count-to";

import {
  UsersIcon,
  ActivityIcon,
  ClockIcon,
  ExternalLinkIcon,
} from '@zhuowenli/vue-feather-icons'
import axios from "axios";

export default {
  components: {
    CountTo,
    UsersIcon,
    ActivityIcon,
    ClockIcon,
    ExternalLinkIcon
  },
  data() {
    return {
      statistics: {}
    }
  },
  computed: {
    total_orders_count() {
      if (!this.statistics.order) {
        return 0;
      }
      return (this.statistics.order.completed || 0) + (this.statistics.order.not_completed || 0);
    },
    incomplete_orders_count() {
      if (!this.statistics.order) {
        return 0;
      }
      return this.statistics.order.not_completed || 0;
    },
    total_invoices_count() {
      if (!this.statistics.invoice) {
        return 0;
      }
      return (this.statistics.invoice.issued || 0) + (this.statistics.invoice.not_issued || 0);
    },
    not_issued_invoice_count() {
      if (!this.statistics.invoice) {
        return 0;
      }
      return this.statistics.invoice.not_issued || 0;
    },
    total_applications_count() {
      if (!this.statistics.application) {
        return 0;
      }
      return (this.statistics.application.complete || 0) + (this.statistics.application.incomplete || 0);
    },
    incomplete_applications_count() {
      if (!this.statistics.application) {
        return 0;
      }
      return this.statistics.application.incomplete || 0;
    },
    total_inquiry_count() {
      if (!this.statistics.inquiry || !this.statistics.inquiry.statuses) {
        return 0;
      }
      return this.statistics.inquiry.statuses.reduce((acc, item) => acc + item.count, 0);
    },
    pending_inquires_count() {
      if (!this.statistics.inquiry || !this.statistics.inquiry.statuses) {
        return 0;
      }
      try {
        return this.statistics.inquiry.statuses.find(item => item.status.toLowerCase() === 'pending').count || 0;
      } catch {
        return 0;
      }
    },
    quoted_inquires_count() {
      if (!this.statistics.inquiry || !this.statistics.inquiry.statuses) {
        return 0;
      }
      try {
        return this.statistics.inquiry.statuses.find(item => item.status.toLowerCase() === 'quoted').count || 0;
      } catch {
        return 0;
      }
    },
    success_inquires_count() {
      if (!this.statistics.inquiry || !this.statistics.inquiry.statuses) {
        return 0;
      }
      try {
        return this.statistics.inquiry.statuses.find(item => item.status.toLowerCase() === 'success').count || 0;
      } catch {
        return 0;
      }
    },
    fail_inquires_count() {
      if (!this.statistics.inquiry || !this.statistics.inquiry.statuses) {
        return 0;
      }
      try {
        return this.statistics.inquiry.statuses.find(item => item.status.toLowerCase() === 'fail').count || 0;
      } catch {
        return 0;
      }
    },
  },
  methods: {
    async getStatistics() {
      try {
        let response = await axios.get('/statistic/statistic_count_by_manager/')
        this.statistics = response.data || {}
      } catch {
        this.statistics = {}
      }
    }
  },
  mounted() {
    this.getStatistics()
  },
};
</script>

<template>
  <b-row>
    <b-col md="3">
      <b-card no-body class="card-animate">
        <b-card-body>
          <div class="d-flex justify-content-between">
            <div>
              <p class="fw-medium text-muted mb-0">Orders</p>
              <div class="d-flex gap-2 my-3">
                <h2 class="ff-secondary fw-semibold mb-0">
                  <count-to :startVal='0' :endVal='incomplete_orders_count' :duration='3000'></count-to>
                </h2>
                <small class="text-warning fw-medium">
                  Not completed
                </small>
              </div>
              <div class="d-flex flex-wrap gap-2">
                <h5 class="mb-0"><span class="badge badge-soft-success">{{ total_orders_count }}</span></h5>
                <span class="text-muted">Total orders</span>
              </div>
            </div>
            <div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <users-icon class="text-info icon-md"></users-icon>
                </span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col md="3">
      <b-card no-body class="card-animate">
        <b-card-body>
          <div class="d-flex justify-content-between">
            <div>
              <p class="fw-medium text-muted mb-0">Invoices</p>
              <div class="d-flex gap-2 my-3">
                <h2 class="ff-secondary fw-semibold mb-0">
                  <count-to :startVal='0' :endVal='not_issued_invoice_count' :duration='3000'></count-to>
                </h2>
                <small class="text-warning fw-medium">
                  Not issued
                </small>
              </div>
              <div class="d-flex flex-wrap gap-2">
                <h5 class="mb-0"><span class="badge badge-soft-success">{{ total_invoices_count }}</span></h5>
                <span class="text-muted">Total invoices</span>
              </div>
            </div>
            <div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <activity-icon name="activity" class="text-info icon-md"></activity-icon>
                </span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col md="3">
      <b-card no-body class="card-animate">
        <b-card-body>
          <div class="d-flex justify-content-between">
            <div>
              <p class="fw-medium text-muted mb-0">Applications</p>
              <div class="d-flex gap-2 my-3">
                <h2 class="ff-secondary fw-semibold mb-0">
                  <count-to :startVal='0' :endVal='incomplete_applications_count' :duration='3000'></count-to>
                </h2>
                <small class="text-warning fw-medium">
                  Not completed
                </small>
              </div>
              <div class="d-flex flex-wrap gap-2">
                <h5 class="mb-0"><span class="badge badge-soft-success">{{ total_applications_count }}</span></h5>
                <span class="text-muted">Total applications</span>
              </div>
            </div>
            <div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <clock-icon name="clock" class="text-info icon-md"></clock-icon>
                </span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col md="3">
      <b-card no-body class="card-animate">
        <b-card-body>
          <div class="d-flex justify-content-between">
            <div>
              <p class="fw-medium text-muted mb-0">Inquiries</p>
              <h2 class="mt-4 ff-secondary fw-semibold">
                <count-to :startVal='0' :endVal='total_inquiry_count' :duration='3000'></count-to>
              </h2>
              <div class="mb-0 text-muted d-flex gap-3 flex-wrap">
                <h5 v-b-tooltip.hover title="Pending inquires count" class="mb-0"><span
                    class="badge badge-soft-warning">{{ pending_inquires_count }}</span></h5>
                <h5 v-b-tooltip.hover title="Quoted inquires count" class="mb-0"><span
                    class="badge badge-soft-secondary">{{ quoted_inquires_count }}</span></h5>
                <h5 v-b-tooltip.hover title="Successfull inquires count" class="mb-0"><span
                    class="badge badge-soft-success">{{ success_inquires_count }}</span></h5>
                <h5 v-b-tooltip.hover title="Failed inquires count" class="mb-0"><span class="badge badge-soft-danger">{{
                    fail_inquires_count
                  }}</span></h5>
              </div>
            </div>
            <div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <external-link-icon class="text-info icon-md"></external-link-icon>
                </span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>

</template>