<script>
import store from "@/state/store.js";
import flatPickr from "vue-flatpickr-component";
import IncompleteOrders from "@/views/pages/dashboard/components/IncompleteOrders.vue";
import UnpaidInvoices from "@/views/pages/dashboard/components/UnpaidInvoices.vue";
import StatisticCounts from "@/views/pages/dashboard/components/StatisticCounts.vue";
import Applications from "@/views/pages/dashboard/components/Applications.vue";
import StatisticsByCompanies from "@/views/pages/orders/components/StatisticsByCompanies.vue";
import PendingInquiries from "@/views/pages/dashboard/components/PendingInquiries.vue";

export default {
  name: "managers_dashboard",
  components: {
    flatPickr,
    IncompleteOrders,
    UnpaidInvoices,
    StatisticCounts,
    Applications,
    StatisticsByCompanies,
    PendingInquiries
  },
  data() {
    return {
      date: new Date().getTime(),
      order_company_ids: []
    }
  },
  computed: {
    sayHelloToUser() {
      let date = new Date();
      let username = store.state.user.full_name.trim().length
          ? store.state.user.full_name
          : store.state.user.email;
      if (date.getHours() < 12) {
        return `Good Morning, ${username} !`;
      } else if (date.getHours() < 18) {
        return `Good Afternoon, ${username} !`;
      } else {
        return `Good Evening, ${username} !`;
      }
    },
  },
  methods: {
    onCompanyIdsLoaded(company_ids_list) {
      this.order_company_ids = company_ids_list || []
    }
  }
}
</script>

<template>
  <div class="row mb-3 pb-1">
    <div class="col-12">
      <div class="d-flex align-items-lg-center flex-lg-row flex-column">
        <div class="flex-grow-1">
          <h4 class="fs-16 mb-1">{{ sayHelloToUser }}</h4>
          <p class="text-muted mb-0">
            Here's what's happening with your account today.
          </p>
        </div>
        <div class="mt-3 mt-lg-0">
          <div class="row g-3 mb-0 align-items-center">
            <div class="col-sm-auto">
              <div class="input-group">
                <flat-pickr :disabled="true"
                            v-model="date" placeholder="Current date"
                            class="form-control border-0 dash-filter-picker shadow">
                </flat-pickr>
                <div class="input-group-text bg-primary border-primary text-white">
                  <i class="ri-calendar-2-line"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <StatisticCounts/>
    </div>
    <div class="col-lg-6">
      <UnpaidInvoices/>
    </div>
    <div class="col-lg-6">
      <IncompleteOrders @on-company-ids-loaded="onCompanyIdsLoaded"/>
    </div>
    <div class="col-lg-12 pb-3">
      <StatisticsByCompanies :company_ids="order_company_ids"/>
    </div>
    <div class="col-lg-8">
      <Applications/>
    </div>
    <div class="col-lg-4">
      <PendingInquiries/>
    </div>
  </div>
</template>

<style scoped>

</style>