<script>
import PageHeader from "@/components/page-header.vue";
import store from "@/state/store.js";
import AdminDashboard from "@/views/pages/dashboard/dashboards/Admin.vue"
import ManagerDashboard from "@/views/pages/dashboard/dashboards/Manager.vue";

export default {
  name: "Index",
  components: {
    PageHeader,
    AdminDashboard,
    ManagerDashboard
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      date: new Date().getTime(),

      roles_allowed: ['Admin', 'Central Asia Manager'],
      blacklisted_users_ids: []
    };
  },
  computed: {
    user() {
      return store.state.user;
    },
    sayHelloToUser() {
      return 'Hello ' + store.state.user.full_name || 'Unknown User';
    },
  }
}
</script>

<template>
  <PageHeader :title="title" :items="items"/>
  <template v-if="roles_allowed.includes(user.role) && !blacklisted_users_ids.includes(user.id)">
    <AdminDashboard/>
  </template>
  <template v-else>
    <ManagerDashboard/>
  </template>
</template>

<style scoped>

</style>