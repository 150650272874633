<template>
  <Multiselect
      class="form-control"
      v-model="selected_item"
      :searchable="true"
      :hideSelected="true"
      :options="options"
      placeholder="Select train"
      @search-change="getOptions($event)"
      :object="true"
      @input="onOptionSelect"
      :caret="true"
      :loading="is_loading"
  >
  </Multiselect>
</template>

<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";


export default {
  name: 'SelectProduct',
  emits: ['onSelect'],
  props: {
    current_item: {
      type: Object,
      default: () => {
      },
    },
    api_url: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: () => 'id'
    },
    label: {
      type: String,
      required: false,
      default: () => 'name'
    },
    minLength: {
      type: Number,
      required: false,
      default: () => 2
    },
    extraDataToBeReturned: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      options: [],
      selected_item: null,
      is_loading: false
    }
  },
  methods: {
    async getOptions(query) {
      if (query.length < this.minLength) return;
      this.is_loading = true
      let options = (await axios.get(`${this.api_url}${query}&limit=10`)).data.results
      this.selected_item = null
      this.options = options.map((option) => {
        let extraData = {}
        this.extraDataToBeReturned.forEach((key) => {
          extraData[key] = option[key]
        })
        return {
          value: option[this.value],
          label: option[this.label],
          ...extraData
        }
      })
      this.is_loading = false
    },
    onOptionSelect(event) {
      this.$emit('onSelect', event === null ? null : event)
    },
    unselectItem() {
      this.selected_item = null
    }
  },
  components: {
    Multiselect,
  },
  mounted() {
    if (this.current_item && Object.keys(this.current_item).length > 0) {
      this.selected_item = {
        value: this.current_item[this.value],
        label: this.current_item[this.label],
      }
      this.options = [this.selected_item]
    } else {
      this.selected_item = null
      this.options = []
    }
  },
  watch: {
    current_item(newVal) {
      if (newVal && Object.keys(newVal).length > 0) {
        this.selected_item = {
          value: newVal[this.value],
          label: newVal[this.label],
        }
        this.options = [this.selected_item]
      } else {
        this.selected_item = null
        this.options = []
      }
    }
  }
}

</script>

<style scoped>

</style>