<script>
import axios from "axios";
import {boolean} from "yup";

export default {
  name: "ProfitableCompanies",
  emits: ['loaded'],
  props: {
    statistics_year: {
      type: Number,
      required: true,
      default: () => new Date().getFullYear()
    },
    loading_statistics_by_selected_year: {
      type: boolean,
      default: () => false
    }
  },
  data() {
    return {
      colorRangeChart: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          legend: {
            show: false,
          },
          chart: {
            height: 350,
            type: "treemap",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
            },
            formatter: function (text, op) {
              return [text, op.value];
            },
            offsetY: -4,
          },
          plotOptions: {
            treemap: {
              enableShades: true,
              shadeIntensity: 0.5,
              reverseNegativeShade: true,
              colorScale: {
                ranges: [
                  {
                    from: -6,
                    to: 0,
                    color: "#3577F1",
                  },
                  {
                    from: 0.001,
                    to: 6,
                    color: "#F06548",
                  },
                ],
              },
            },
          },
        },
      },
      request_status: "",
      searchText: "",
      originalData: []
    }
  },
  methods: {
    async getStatistics() {
      this.request_status = "loading"
      try {
        let response = await axios.get('/statistic/top_customers_overall/', {
          params: {
            year: this.statistics_year
          }
        })
        this.colorRangeChart.series[0].data = (response.data.top_customers.companies || []).map(company => {
          return {
            x: company.name || "Others",
            y: parseFloat(company.agreed_rate || 0).toFixed(2)
          }
        })
        this.originalData = this.colorRangeChart.series[0].data
        this.request_status = "success"
        this.$emit('loaded', true)
      } catch {
        this.$emit('loaded', true)
        this.request_status = "error"
        console.log("Error while loading statistics")
      }
    },
    updateSeries(searchText) {
      this.showChart = false
      let series = this.originalData
      if (searchText) {
        series = series.filter(item => item.x.toLowerCase().includes(searchText.toLowerCase().trim()))
      }
      this.colorRangeChart.series[0].data = series
      this.showChart = true
    }
  },
  watch: {
    searchText(newValue) {
      this.$nextTick(() => {
        this.updateSeries(newValue)
      })
    }
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-0">
        <span v-if="!loading_statistics_by_selected_year" class="badge badge-gradient-info me-2">{{ statistics_year }}</span>
        <i v-else class="mdi mdi-spin mdi-loading me-2 text-success"></i>
        Profitable Companies
      </b-card-title>
    </b-card-header>
    <b-card-body v-if="request_status === 'success'">
      <div class="mb-2">
        <input type="text" class="form-control" placeholder="Search company name..." v-model="searchText">
      </div>
      <apexchart ref="chart" class="apex-charts" height="450" dir="ltr" :series="colorRangeChart.series"
                 :options="colorRangeChart.chartOptions"></apexchart>
    </b-card-body>
    <b-card-body v-else-if="request_status === 'loading'" style="min-height: 400px">
      <b-spinner class="align-middle"></b-spinner>
    </b-card-body>
    <b-card-body v-else-if="request_status === 'error'">
      <div class="text-center">
        <h5 class="text-danger">Error while loading statistics</h5>
        <p>Please try again later</p>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>