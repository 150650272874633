<script>
import axios from "axios";
import {VueEcharts} from "vue3-echarts";
import CustomerDetailsSearch from "@/views/pages/dashboard/components/CustomerDetailsSearch.vue";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  name: "CustomerDetails",
  emits: ['on_year_select'],
  data() {
    return {
      showModal: false,
      customer: {},

      showChart: false,

      mixedBarChart: {
        dataZoom: [
          {
            type: 'slider', // Use slider for scrolling
            show: true,
            start: 0,      // Initial zoom range (percentage)
            end: 0,      // Initial zoom range (percentage)
            xAxisIndex: 0  // Specify the x-axis index
          }
        ],
        // Setup grid
        grid: {
          zlevel: 0,
          x: 80,
          x2: 50,
          y: 30,
          y2: 30,
          borderWidth: 0,
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          orient: 'center',
          left: 0,
          top: 20,
          feature: {
            dataView: {
              readOnly: false,
              title: "Data View"
            },
            magicType: {
              type: ['line', 'bar'],
              title: {
                line: "For line chart",
                bar: "For bar chart"
              }
            },
            restore: {
              title: "restore"
            },
            saveAsImage: {
              title: "Download Image"
            }
          }
        },
        color: getChartColorsArray('["--vz-primary","--vz-danger","--vz-success"]'),
        legend: {
          data: ['Total Revenue', 'Total Expense', 'Total Profit'],
          textStyle: {
            color: '#8791af'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#8791af'
              },
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Total Revenue',
            min: 0,
            interval: 10000,
            axisLine: {
              lineStyle: {
                color: '#8791af'
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(166, 176, 207, 0.1)"
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: 'Total Profit',
            min: 0,
            interval: 5000,
            axisLine: {
              lineStyle: {
                color: '#8791af'
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(166, 176, 207, 0.1)"
              }
            },
            axisLabel: {
              formatter: '{value} Ã‚Â°C'
            }
          }
        ],
        series: [
          {
            name: 'Total Revenue',
            type: 'bar',
            data: []
          },
          {
            name: 'Total Expense',
            type: 'bar',
            data: []
          },
          {
            name: 'Total Profit',
            type: 'line',
            yAxisIndex: 1,
            data: []
          }
        ]
      }
    }
  },
  components: {
    VueEcharts,
    CustomerDetailsSearch
  },
  methods: {
    async openModal(customer) {
      if (customer) {
        if (customer.slug) {
          this.customer = customer
          await this.getCustomerDetails()
          this.showModal = true
        } else {
          alert("Please select a valid customer")
        }
      } else {
        this.showModal = false
        this.customer = {}
      }
    },
    async getCustomerDetails() {
      if (!this.customer) return
      this.showChart = false
      try {
        let response = await axios.get(`/statistic/customer_detail/${this.customer.slug}/`)
        this.customer = {
          ...this.customer,
          ...response.data.customer
        }
        this.mixedBarChart.xAxis[0].data = []
        this.mixedBarChart.series[0].data = []
        this.mixedBarChart.series[1].data = []
        this.mixedBarChart.series[2].data = []
        response.data.customer.orders.forEach(order => {
          this.mixedBarChart.xAxis[0].data.push(order.order_number.toString())
          this.mixedBarChart.series[0].data.push(order.total_revenue || 0)
          this.mixedBarChart.series[1].data.push(order.total_expenses || 0)
          this.mixedBarChart.series[2].data.push(order.total_profit || 0)
        })
        this.mixedBarChart.dataZoom[0].end = this.mixedBarChart.xAxis[0].data.length < 30 ? 100 : 30
        this.showChart = true
      } catch {
        alert("error")
      }
    },
    onCustomerSelect(customer) {
      this.openModal(customer)
    },
    onYearSelect(year) {
      this.$emit('on_year_select', year)
    }
  },
}
</script>

<template>
  <b-modal v-model="showModal" :title="customer ? customer.label : 'Customer'" fullscreen centered hide-footer>

    <b-row class="justify-content-between align-items-end mb-5">
      <b-col lg="4">
        <CustomerDetailsSearch
            @onYearSelect="onYearSelect"
            @onCustomerSelect="onCustomerSelect"
        />
      </b-col>
      <b-col lg="8">
        <div class="d-flex flex-wrap justify-content-end gap-5">
          <div>
            <h2 :class="{
          'text-danger' : (customer.total_agreed_rate || 0) < 0,
        }" class="mb-0">$ {{ parseFloat(customer.total_agreed_rate || 0).toLocaleString() }}</h2>
            <span>Total agreed rate</span>
          </div>
          <div>
            <h2 :class="{
          'text-danger' : (customer.total_payment || 0) < 0,
        }" class="mb-0">$ {{ parseFloat(customer.total_payment || 0).toLocaleString() }}</h2>
            <span>Total payment</span>
          </div>
          <div>
            <h2 :class="{
          'text-danger' : (customer.balance || 0) < 0,
        }"
                class="mb-0">$ {{ parseFloat(customer.balance || 0).toLocaleString() }}</h2>
            <span>Balance</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if="showChart">
      <vue-echarts :option="mixedBarChart" style="height: 600px" ref="chart"/>
    </div>
  </b-modal>
</template>

<style scoped>

</style>