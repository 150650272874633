<script>
import {CountTo} from "vue3-count-to";
import moment from "moment/moment";
import axios from "axios";

export default {
  name: "KPIScores",
  components: {
    CountTo
  },
  data() {
    return {
      showModal: false,
      manager: null,
      manager_scores: [],
      is_loading_kpi_scores: false,
      error_loading_kpi_scores: false
    }
  },
  computed: {
    total_invoices_count() {
      if (this.manager) {
        return (this.manager.invoice.issued || 0) + (this.manager.invoice.not_issued || 0)
      }
      return 0
    },
    total_inquiries_count() {
      if (this.manager) {
        return (this.manager.inquiry.pending || 0) + (this.manager.inquiry.quoted || 0) + (this.manager.inquiry.success || 0) + (this.manager.inquiry.fail || 0)
      }
      return 0
    }
  },
  methods: {
    openModal(manager) {
      if (manager) {
        this.manager = manager
        this.showModal = true
      } else {
        alert("Manager not found!")
      }
    },
    formatLastLogin(date) {
      if (date) {
        return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('LLL');
      }
      return '-'
    },
    formatKPIScoreDate(date) {
      if (date) {
        return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').format('LLL');
      }
      return '-';
    },
    async getKPIScoresByManager() {
      this.is_loading_kpi_scores = true
      this.error_loading_kpi_scores = false
      try {
        let response = await axios.get(`/kpi/list/${this.manager.manager.id}/`)
        this.manager_scores = response.data.results || []
        this.is_loading_kpi_scores = false
        this.error_loading_kpi_scores = false
      } catch {
        this.manager_scores = []
        this.is_loading_kpi_scores = false
        this.error_loading_kpi_scores = true
      }
    }
  },
  watch: {
    manager: {
      handler(newValue) {
        if (newValue) {
          this.getKPIScoresByManager()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="KPI Score Details" hide-footer centered hide-header size="lg">
    <div v-if="manager">
      <div class="row gy-4 mb-4">
        <div class="col-12 col-xl-4">
          <div class="bg-soft-success rounded-3 py-3 h-100">
            <div class="d-flex flex-column align-items-center justify-content-center gap-2">
              <div class="avatar-sm flex-shrink-0 me-1">
                  <span class="avatar-title fs-4 bg-light text-success border border-success rounded-circle">
                    {{ manager.manager.username[0].toUpperCase() }}
                  </span>
              </div>
              <span class="fs-4">{{ manager.manager.username }}</span>
              <span>{{ formatLastLogin(manager.manager.last_login) }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-8">
          <div class="row justify-content-between g-3">
            <div class="col-12 col-lg-6">
              <div class="d-flex align-items-center justify-content-between gap-3 px-3 rounded-3 border py-2">
                <div>
                  <h4>
                    <count-to :startVal="0" :endVal="manager.total_score || 0 " :duration="2000"></count-to>
                  </h4>
                  <span>KPI Score</span>
                </div>
                <img style="height: 45px; width: 45px" src="@/assets/images/kpi.png" alt="Order Icon">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex align-items-center justify-content-between gap-3 px-3 rounded-3 border py-2">
                <div>
                  <h4>
                    <count-to :startVal="0" :endVal="manager.order_count || 0 " :duration="2000"></count-to>
                  </h4>
                  <span>Orders</span>
                </div>
                <img style="height: 40px; width: 40px" src="@/assets/images/order-diamond-icon.webp" alt="Order Icon">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex align-items-center justify-content-between gap-3 px-3 rounded-3 border py-2">
                <div>
                  <h4>
                    <count-to :startVal="0" :endVal="total_invoices_count" :duration="2000"></count-to>
                  </h4>
                  <span>Invoices</span>
                </div>
                <img style="height: 40px; width: 40px" src="@/assets/images/invoice.png" alt="Order Icon">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex align-items-center justify-content-between gap-3 px-3 rounded-3 border py-2">
                <div>
                  <h4>
                    <count-to :startVal="0" :endVal="total_inquiries_count" :duration="2000"></count-to>
                  </h4>
                  <span>Inquires</span>
                </div>
                <img style="height: 40px; width: 40px" src="@/assets/images/inquiry.webp" alt="Order Icon">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <thead>
          <tr>
            <th scope="col">Manager</th>
            <th scope="col">Date Performed</th>
            <th scope="col">Action</th>
            <th scope="col" class="text-center">Point Given</th>
          </tr>
          </thead>

          <template v-if="is_loading_kpi_scores">
            <tbody>
            <tr>
              <td colspan="4" class="text-muted">
                <div class="d-flex align-items-center gap-3">
                  <i class="mdi mdi-loading mdi-spin align-middle"></i>
                  <span>
                    Loading...
                  </span>
                </div>
              </td>
            </tr>
            </tbody>
          </template>

          <template v-else>
            <tbody v-if="!error_loading_kpi_scores">
            <template v-for="kpi_score in manager_scores" :key="`kpi_score_${kpi_score.id}`">
              <tr>
                <td>{{ kpi_score.manager.username }}</td>
                <td>{{ formatKPIScoreDate(kpi_score.timestamp) }}</td>
                <td>{{ kpi_score.action_type.action_type }}</td>
                <td class="text-center">
                  <a class="link-success fw-medium">
                    + {{ kpi_score.action_type.points }}
                  </a>
                </td>
              </tr>
            </template>
            <tr v-if="manager_scores.length === 0">
              <td colspan="4" class="text-muted">
                No KPI Scores yet!
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="4" class="text-center">
                Couldn't load the scores this time (
              </td>
            </tr>
            </tbody>
          </template>
        </table>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>