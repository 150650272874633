<script>
import OrderStatistics from "@/views/pages/dashboard/statistics/OrderStatistics.vue";
import OrdersByType from "@/views/pages/dashboard/statistics/OrdersByType.vue";
import OrdersMonthly from "@/views/pages/dashboard/statistics/OrdersMonthly.vue";
import TrainStatistics from "@/views/pages/dashboard/statistics/TrainStatistics.vue";
import QuickSearches from "@/views/pages/dashboard/statistics/QuickSearches.vue";
import ProfitableCompanies from "@/views/pages/dashboard/statistics/ProfitableCompanies.vue";
import TrainAverageTime from "@/views/pages/dashboard/statistics/TrainAverageTime.vue";
import ManagersStatistics from "@/views/pages/dashboard/components/ManagersStatistics.vue";
import SalesReport from "@/views/pages/dashboard/statistics/SalesReport.vue";
import Swal from "sweetalert2";

export default {
  name: "managers_dashboard",
  data() {
    return {
      date: new Date().getTime(),
      isElementActive: false,
      show_train_statistics_table: false,
      statistics_year: new Date().getFullYear(),
      times_statistics_year_changed: 0,
      loading_statistics_by_selected_year: false
    }
  },
  components: {
    OrderStatistics,
    OrdersByType,
    OrdersMonthly,
    TrainStatistics,
    QuickSearches,
    ProfitableCompanies,
    TrainAverageTime,
    ManagersStatistics,
    SalesReport
  },
  methods: {
    openCustomerStatisticsModal(event) {
      this.$refs.quickSearches.openCustomerStatisticsModal(event)
    },
    onTrainAverageTimeLoaded() {
      if (this.times_statistics_year_changed >= 1) {
        this.loading_statistics_by_selected_year = false
        let Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 5000
        });
        Toast.fire({
          icon: 'success',
          title: `Finished loading statistics for - ${this.statistics_year}`,
        })
      }
    },
    updateMonthlyOrderStatisticsChart(event) {
      if (event) {
        if (!event.position) return alert('Invalid position')
        this.$refs.ordersMonthly.getStatistics(event.position)
      } else {
        this.$refs.ordersMonthly.getStatistics()
      }
    },
    onLoadFinished(refName) {
      try {
        setTimeout(async () => {
          await this.$refs[refName].getStatistics()
        }, 1000)
      } catch {
        console.log('Error loading statistics for ' + refName)
      }
    },
    showTrainStatisticsTable() {
      if (this.show_train_statistics_table) {
        this.$refs.trainStatisticsTable.$refs.table.getData()
      } else {
        setTimeout(() => {
          this.show_train_statistics_table = true
        }, 500)
      }
    },
    onYearSelect(year) {
      this.statistics_year = year
    }
  },
  watch: {
    statistics_year() {
      this.times_statistics_year_changed++
      let Toast = Swal.mixin({
        toast: true,
        position: "bottom",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 5000
      });
      Toast.fire({
        icon: 'success',
        title: `Started loading statistics for - ${this.statistics_year}`,
      })
      this.loading_statistics_by_selected_year = true
      this.$router.push({
        query: {
          year: this.statistics_year
        }
      })
      this.$refs.orderStatistics.getStatistics()
    }
  },
  mounted() {
    this.$router.push({
      query: {
        year: this.statistics_year
      }
    })
  }
}
</script>

<template>
  <QuickSearches ref="quickSearches"
                 @on_year_select="onYearSelect"
                 @managers-dashboard-tab-clicked="this.$refs.managersStatistics.getManagersStatistics()"/>

  <div class="tab-content">
    <div class="tab-pane show active" id="main-dashboard">
      <b-row class="project-wrapper">
        <b-col xxl="9">
          <OrderStatistics ref="orderStatistics" @loaded="onLoadFinished('ordersMonthly')"
                           :statistics_year="statistics_year"
                           @updateMonthlyOrderStatisticsChart="updateMonthlyOrderStatisticsChart"
          />
          <OrdersMonthly ref="ordersMonthly" :statistics_year="statistics_year"
                         :loading_statistics_by_selected_year="loading_statistics_by_selected_year"
                         @loaded="onLoadFinished('profitableCompanies')"/>
          <ProfitableCompanies ref="profitableCompanies" :statistics_year="statistics_year"
                               :loading_statistics_by_selected_year="loading_statistics_by_selected_year"
                               @loaded="showTrainStatisticsTable()"
          />
          <div class="mt-3" v-if="show_train_statistics_table">
            <TrainStatistics ref="trainStatisticsTable" @loaded="onLoadFinished('ordersByType')"/>
          </div>
        </b-col>
        <b-col xxl="3">
          <SalesReport/>

          <OrdersByType ref="ordersByType" @loaded="onLoadFinished('trainAverageTime')"
                        @openCustomerStatisticsModal="openCustomerStatisticsModal"
                        :statistics_year="statistics_year"
                        :loading_statistics_by_selected_year="loading_statistics_by_selected_year"
          />
          <TrainAverageTime @loaded="onTrainAverageTimeLoaded()"
                            ref="trainAverageTime" :statistics_year="statistics_year"
                            :loading_statistics_by_selected_year="loading_statistics_by_selected_year"
          />
        </b-col>
      </b-row>
    </div>
    <div ref="managersDashboardTabContent" class="tab-pane" id="managers-dashboard">
      <ManagersStatistics ref="managersStatistics"/>
    </div>
  </div>

</template>

<style scoped>
</style>