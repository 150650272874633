<script>
import axios from "axios";
import Swal from "sweetalert2";
import EditInquiryModal from "@/views/pages/inquires/modals/EditInquiry.vue";
import InquiryToSuccess from "@/views/pages/inquires/modals/InquiryToSuccess.vue";

export default {
  components: {
    EditInquiryModal,
    InquiryToSuccess
  },
  data() {
    return {
      inquiries: []
    }
  },
  methods: {
    async getPendingInquiries() {
      try {
        let response = await axios.get('/statistic/statistic_pending_inquiries_by_manager/')
        this.inquiries = response.data.results
      } catch {
        let Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        await Toast.fire({
          icon: 'error',
          title: 'Failed to fetch pending inquiries'
        })
        this.inquiries = []
      }
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    closeAnyOPenDropdowns(id) {
      setTimeout(() => {
        const openDropdowns = document.querySelectorAll('.dropdown-menu.show');
        openDropdowns.forEach((dropdown) => {
          if ((dropdown.id && dropdown.id) === id) {
            return;
          }
          dropdown.classList.remove('show');
        });
      }, 3)
    },
    async changeStatus(inquiry, new_status) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      inquiry.is_saving_status = true;
      try {
        await axios.put(`/inquiry/update_with_notification/${inquiry.id}/`, {
          sender_id: inquiry.sender.id,
          text: inquiry.text,
          description: inquiry.description,
          status: new_status,
          sales_manager_id: inquiry.sales_manager ? inquiry.sales_manager.id : null,
          client: inquiry.client
        })
        inquiry.is_saving_status = false;
        inquiry.status = new_status;
        await Toast.fire({
          icon: "success",
          title: "Inquiry status updated successfully",
        });
      } catch {
        inquiry.is_saving_status = false;
        await Toast.fire({
          icon: "error",
          title: "Error updating inquiry status",
        });
      }
    },
  },
  mounted() {
    this.getPendingInquiries()
  }
}
</script>

<template>
  <EditInquiryModal ref="editInquiryModal" @onInquiryUpdated="getPendingInquiries()"/>
  <InquiryToSuccess ref="inquiryToSuccess" @inquiry-marked-as-success="getPendingInquiries()"/>

  <b-card no-body>
    <b-card-header class="d-flex align-items-center">
      <h6 class="card-title mb-0 flex-grow-1">My Pending Inquiries</h6>
      <router-link :to="{
        name: 'Inquires', query: {
          status: 'pending'
        }
      }" class="text-muted">
        See Pending Inquiries <i class="ri-arrow-right-line align-bottom"></i>
      </router-link>
    </b-card-header>
    <b-card-body>
      <div class="table-responsive table-card" v-if="inquiries && inquiries.length> 0">
        <div>
          <ul class="list-group list-group-flush">
            <template v-for="inquiry in inquiries" :key="`pending_inquiry_${inquiry}`">
              <li
                  class="list-group-item list-group-item-action ">
                <div class="d-flex align-items-center">
                  <div @click="this.$refs.editInquiryModal.openModal(inquiry)"
                       style="min-width: 35px" v-b-tooltip.hover
                       :title="`Sales Manager: ${inquiry.sales_manager.username}`"
                       class="avatar-xs object-cover rounded-circle bg-soft-secondary text-secondary d-flex justify-content-center align-items-center">
                    {{ inquiry.sales_manager ? inquiry.sales_manager.username[0].toUpperCase() : 'U' }}
                  </div>
                  <div @click="this.$refs.editInquiryModal.openModal(inquiry)"
                       class="ms-3 flex-grow-1 text-truncate overflow-hidden">
                    <div class="text-truncate pe-5">
                      {{ cleanText(inquiry.text) }}
                    </div>
                    <p class="mb-0 text-muted">
                      sender: {{ inquiry.sender ? inquiry.sender.username : 'Unknown' }}
                    </p>
                  </div>
                  <div>
                    <div class="dropdown">
                      <div data-bs-toggle="dropdown">
            <span @click="closeAnyOPenDropdowns(`inquiry_status_${inquiry.id}`)" v-if="inquiry.status === 'success'"
                  class="badge rounded-pill border border-success text-success">Success</span>
                        <span @click="closeAnyOPenDropdowns(`inquiry_status_${inquiry.id}`)"
                              v-else-if="inquiry.status === 'fail'"
                              class="badge rounded-pill border border-danger text-danger">Fail</span>
                        <span @click="closeAnyOPenDropdowns(`inquiry_status_${inquiry.id}`)"
                              v-else-if="inquiry.status === 'pending'"
                              class="badge rounded-pill border border-warning text-warning">Pending</span>
                        <span @click="closeAnyOPenDropdowns(`inquiry_status_${inquiry.id}`)"
                              v-else-if="inquiry.status === 'quoted'"
                              class="badge rounded-pill border border-secondary text-secondary">Quoted</span>
                      </div>
                      <div class="dropdown-menu dropdown-menu-end" :id="`inquiry_status_${inquiry.id}`">
                        <div class="px-3 py-0">
                          <small v-if="inquiry.is_saving_status !== true" class="text-muted">Mark as</small>
                          <small class="text-muted" v-else>
                            <i class="mdi mdi-loading mdi-spin me-2"></i>
                            Saving...
                          </small>
                        </div>
                        <div
                            class="dropdown-item notify-item cursor-pointer"
                            :class="{'active': inquiry.status === 'success'}"
                            @click="this.$refs.inquiryToSuccess.openModal(inquiry)"
                        >
                          <i class="mdi mdi-check text-success me-2 my-0"></i>
                          <h6 class="my-0 py-0 d-inline">Success</h6>
                        </div>

                        <div
                            class="dropdown-item notify-item cursor-pointer"
                            :class="{'active': inquiry.status === 'quoted'}"
                            @click="changeStatus(inquiry, 'quoted')"
                        >
                          <i class="mdi mdi-check text-secondary me-2 my-0"></i>
                          <h6 class="my-0 py-0 d-inline">Quoted</h6>
                        </div>

                        <div
                            class="dropdown-item notify-item cursor-pointer"
                            :class="{'active': inquiry.status === 'pending'}"
                            @click="changeStatus(inquiry, 'pending')"
                        >
                          <i class="mdi mdi-clock text-warning me-2 my-0"></i>
                          <h6 class="my-0 py-0 d-inline">Pending</h6>
                        </div>

                        <div
                            class="dropdown-item notify-item cursor-pointer"
                            :class="{'active': inquiry.status === 'fail'}"
                            @click="changeStatus(inquiry, 'fail')"
                        >
                          <i class="bx bx-x text-danger me-2 my-0 fs-5"></i>
                          <h6 class="my-0 py-0 d-inline">Fail</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <div v-else class="py-5 text-center">
        <h5 class="mb-0 text-muted">
          No pending inquiries ✅
        </h5>
      </div>
    </b-card-body>
  </b-card>
</template>