<script>
import Table from "@/components/tables/table.vue";
import TrainDetails from "@/views/pages/dashboard/modals/TrainDetails.vue";

export default {
  name: "TrainStatistics",
  emits: ['loaded'],
  data() {
    return {
      table: {
        name: 'Train statistics',
        headers: [
          {
            field: 'lot_number',
            label: 'Train',
            align: 'center'
          },
          {
            field: 'total_revenue',
            label: 'Total Revenue',
            align: 'center',
            searchable: false
          },
          {
            field: 'total_expanse',
            label: 'Total Expense',
            align: 'center',
            searchable: false
          },
          {
            field: 'total_profit',
            label: 'Total Profit',
            align: 'center',
            sortable: true,
            searchable: false
          },
          {
            field: 'year',
            label: 'Year',
            align: 'center',
            sortable: true,
            searchable: false
          }
        ],
        url: '/statistic/train_statistic_finance/',
        selectable: true,
        searchable: true,
      },
    }
  },
  components: {
    Table,
    TrainDetails
  },
  methods: {
    onRowClick(data) {
      this.$refs.trainDetails.openModal({...data})
    }
  },
}
</script>

<template>

  <TrainDetails ref="trainDetails"/>

  <div class="mb-4">
    <Table ref="table" v-bind="table" @onRowClick="onRowClick" @onLoaded="this.$emit('loaded', true)" :per-page="20">
      <template v-slot:lot_number="{row: data}">
        <span class="fw-medium">{{ data.lot_number || '--' }}</span>
      </template>
      <template v-slot:total_revenue="{row: data}">
        <span>$ {{ parseFloat(data.total_revenue || 0).toLocaleString() }}</span>
      </template>
      <template v-slot:total_expanse="{row: data}">
        <span>$ {{ parseFloat(data.total_expanse || 0).toLocaleString() }}</span>
      </template>
      <template v-slot:total_profit="{row: data}">
        <h5 class="mb-0">
        <span
            :class="{
        'badge-soft-success': parseFloat(data.total_revenue || 0) - parseFloat(data.total_expanse || 0) >= 0,
        'badge-soft-danger': parseFloat(data.total_revenue || 0) - parseFloat(data.total_expanse || 0) < 0,
          }"
            class="badge">
        $ {{ (parseFloat(data.total_revenue || 0) - parseFloat(data.total_expanse || 0)).toLocaleString() }}
      </span>
        </h5>
      </template>
    </Table>
  </div>
</template>

<style scoped>

</style>