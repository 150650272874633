<script>
import CustomerDetailsSearch from "@/views/pages/dashboard/components/CustomerDetailsSearch.vue";
import CustomerDetailsModal from "@/views/pages/dashboard/modals/CustomerDetails.vue";

export default {
  name: "QuickSearches",
  emits: ['managers-dashboard-tab-clicked', 'on_year_select'],
  components: {
    CustomerDetailsSearch,
    CustomerDetailsModal
  },
  methods: {
    openCustomerStatisticsModal(customer) {
      this.$refs.customerDetailsModal.openModal(customer)
    },
    onYearSelect(year) {
      this.$emit('on_year_select', year)
    }
  }
}
</script>

<template>
  <CustomerDetailsModal ref="customerDetailsModal" @on_year_select="onYearSelect"/>
  <b-card no-body>
    <b-card-body>
      <b-row class="align-items-end g-3">
        <b-col sm="12" lg="7">
          <ul class="nav nav-tabs nav-justified" role="tablist">
            <li class="nav-item">
              <b-link class="nav-link active" data-bs-toggle="tab" href="#main-dashboard" role="tab"
                      aria-selected="false">
                Main Dashboard
              </b-link>
            </li>
            <li @click="this.$emit('managers-dashboard-tab-clicked')" class="nav-item">
              <b-link class="nav-link" data-bs-toggle="tab" href="#managers-dashboard"
                      role="tab" aria-selected="false">
                Managers Statistics
              </b-link>
            </li>
          </ul>
        </b-col>
        <b-col sm="12" lg="5">
          <CustomerDetailsSearch
              @onCustomerSelect="openCustomerStatisticsModal"
              @onYearSelect="onYearSelect"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>